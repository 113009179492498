import { render, staticRenderFns } from "./draftReviewqy.vue?vue&type=template&id=7e629fe5&scoped=true&"
import script from "./draftReviewqy.vue?vue&type=script&lang=js&"
export * from "./draftReviewqy.vue?vue&type=script&lang=js&"
import style0 from "./draftReviewqy.vue?vue&type=style&index=0&id=7e629fe5&scoped=true&lang=css&"
import style1 from "./draftReviewqy.vue?vue&type=style&index=1&id=7e629fe5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e629fe5",
  null
  
)

export default component.exports