<template>
  <div>
    <el-table :data="tableData" :row-class-name="tabRowClassName" style="width: 100%"
        :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
        <el-table-column label="序号" type="index">
        </el-table-column>
        <el-table-column label="文件名称" prop="name" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <el-button type="primary" @click="download(row)">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
  </div>
</template>
<script>
import { Loading } from "element-ui";
import { addfiles, getBiaozhunDetail } from "../../../../api/project";
export default {
  filters: {
    shenchaType(type) {
      if (type == 1 || type == 4) {
        return "初审";
      } else if (type == 2 || type == 5) {
        return "复审";
      } else if (type == 3 || type == 6) {
        return "终审";
      } else if (type == 7) {
        return "最终意见";
      }
    },
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd hh:mm:ss";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      materImgList1: [],
      materImgList2: [],
      materImgList3: [],
      materImgList4: [],
      materImgList5: [],
      materImgList6: [],
      materImgList7: [],
      materImgList8: [],
      materImgList10: [],
      materImgList9: [],
      materImgList: [],
      nowType: 1,
      reverse: false,
      types: [],
      activities: [],
      form: {},
      disabled: false,
      todisabled: true,
      loadingInstance: "",
      tableData:[]
    };
  },
  mounted() {
    this.getIdAndToken();
  },
  methods: {
    zhanshi2(item) {
      console.log("file", item);
      // this.dialogfiles = true;
      // this.viewFile(row.url, row.name)
      window.open(item.url);
    },
    tabRowClassName({ rowIndex }) {
      let index = rowIndex + 1;
      if (index % 2 == 0) {
        return "warning-row";
      }
    },
    zhanshi(item) {
      console.log("2222");
      // this.dialogfiles = true;
      // this.viewFile(row.url, row.name)
      window.open(item.url);
    },
    activeFile(type) {
      if (type == 1 || type == 4) {
        return this.materImgList9.filter((item) => item.type == 41);
      } else if (type == 2 || type == 5) {
        return this.materImgList9.filter((item) => item.type == 42);
      } else if (type == 3 || type == 6) {
        return this.materImgList9.filter((item) => item.type == 43);
      } else if (type == 7) {
        return this.materImgList9.filter((item) => item.type == 45);
      }
    },
    getLeftTime() {
      //开始的毫秒数
      let start;
      if (this.activities && this.activities.length > 0) {
        start = this.activities[this.activities.length - 1].updated_at
      } else if (this.form.examines && this.form.examines.length > 0) {
        start = this.form.examines[this.form.examines.length - 1].updated_at;
      }
      //到期的时间 毫秒数
      let end = new Date(start).getTime() + 4 * 30 * 24 * 60 * 60 * 1000
      //剩余的毫秒数
      let left = end - new Date().getTime();

      let day = parseInt(left / (24 * 60 * 60 * 1000)) % 30
      let month = parseInt(left / (30 * 24 * 60 * 60 * 1000))
      let result = day + "天截止"
      if (month) {
        result = month + "月" + result
      }
      return "剩余" + result
    },
    showNowType() {
      let lastType = 0;
      if (this.activities.length > 0) {
        lastType = this.activities[this.activities.length - 1].type;
      }
      if (lastType == 0 || lastType == 4 || lastType == 5 || lastType == 6) {
        this.nowType = 1;
      } else if (lastType == 1) {
        this.nowType = 2;
      } else if (lastType == 2) {
        this.nowType = 3;
      } else if (lastType == 3) {
        this.nowType = 7;
      } else {
        this.nowType = -1;
      }
      console.log(this.activities, this.nowType)
      this.$emit('changeNowType', this.nowType == -1 ? 7 : this.nowType)
    },
    showLoad(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ["pdf", "jpg", "png", "doc", "docx"];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message("上传文件只能是 pdf、jpg、png、doc、docx格式", "error");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 16;
      if (isLt2M) {
        this.loadingInstance = Loading.service({ fullscreen: true });
        console.log(this.loadingInstance);
      }
      if (!isLt2M) {
        this.$message("文件格式不正确或文件的大小超出上限");
      }
      return isLt2M;
    },
    goback() {
      this.$router.go(-1);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制上传1个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    getIdAndToken() {
      this.id = this.$route.query.id;
      this.token = this.$store.state.token; //获取token
      this.getInfo();
    },
    //获取详情
    getInfo() {
      getBiaozhunDetail({ id: this.id, token: this.token })
        .then((res) => {
          // console.log("detial", res);
          this.htmlTitle = res.data.proName;
          this.form = res.data;
          // this.tableData = res.data.fu; //主要起草人员展开无分页
          // this.materImgList = res.data.files;
          this.tableData = res.data.files.filter(
            (item) => item.type == 32
          );
          // console.log('打印文件的数组', this.tableData)
          // let activities = res.data.examines.filter(
          //   (item) => item.status == 27 && (item.type == 1)
          // );
          // if (activities && activities.length) {
          //   this.activities = [activities[activities.length - 1]]
          // }

          // this.materImgList1 = res.data.files.filter((item) => item.type == 25);
          // this.materImgList2 = res.data.files.filter((item) => item.type == 26);
          // this.materImgList3 = res.data.files.filter((item) => item.type == 27);
          // this.materImgList4 = res.data.files.filter((item) => item.type == 28);
          // this.materImgList5 = res.data.files.filter((item) => item.type == 29);
          // this.materImgList6 = res.data.files.filter((item) => item.type == 30);
          // this.materImgList7 = res.data.files.filter((item) => item.type == 31);
          // this.materImgList8 = res.data.files.filter((item) => item.type == 32);
          // this.materImgList10 = res.data.files.filter((item) => item.type == 33);
          
          this.showNowType();
          console.log(this.form);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
/deep/.el-timeline-item__node--normal {
  margin-top: 8px;
}
</style>
<style scoped lang="scss">
.chfiles {
  border-top: 1px solid #d8d8d8;
  margin-top: 10px;
  padding-top: 10px;
}

.cgfleftto {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.cgfleftto:hover {
  color: #3082e2;
  cursor: pointer;
}

.filename {
  cursor: pointer;
}

.filename:hover {
  color: #3082e2;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.shehe {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.flexrow_tt {
  display: flex;
  flex-direction: row;
}

.checktitle {
  font-size: 18px;
  font-weight: bold;
  color: #999999;
  padding-top: 3px;
}

.sendbtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.flexrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tilelft {
  font-size: 22px;
  font-weight: bold;
  color: #1a1a1a;
}

.tiecg {
  margin-bottom: 20px;
}

.lefttie {
  border-bottom: 1px solid #dbe9ef;
  padding: 5px;

  .ltleft {
    border-left: 4px solid #3082e2;
    padding-left: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #3082e2;
  }

  .ltright {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.leftcg {
  width: 64%;
  // background: #ffffff;
}

.rightcg {
  width: 35%;
  height: 73vh;
  overflow: hidden;
  padding-right: 20px;
  overflow-y: scroll;
  padding-left: 40px
}

.rightcg::-webkit-scrollbar {
  display: none;
}

.linetie {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  margin-top: 15px;

  .lttime {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }

  .ltmanger {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    padding-right: 10px;
  }
}

.kuang {
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.29);
  margin: 10px 0;
  padding: 10px 15px;
}

.cr-card {
  .checkcont {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    // border-bottom: 1px solid #dbe9ef;
  }
}

.tgbtn {
  padding: 30px 58px;
}

.leftfile {
  padding: 10px 20px;
  background-color: #ffffff;
  height: 70vh;
  overflow-y: scroll;

  .filelistcg {
    .cgfiles {
      // height: 54px;
      background: #f4f4f4;
      // line-height: 54px;
      padding: 20px 15px;
      margin-bottom: 10px;

      .cgtop:nth-child(1) {
        border-bottom: 1px solid #dbe9ef;
      }

      .cgtop:nth-child(2) {
        margin-top: 10px;
      }

      .cgfleft {
        font-size: 16px;
        font-weight: 400;
        width: 80%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #666666;

        .beizhu {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          padding-left: 15px;
          height: 35px;
          line-height: 35px;
        }
      }

      .cgfrig {
        font-size: 14px;
        font-weight: 400;
        color: #f56c6c;
        cursor: pointer;
      }
    }
  }
}

.leftfile::-webkit-scrollbar {
  display: none;
}
</style>
