<template>
  <div>
    <div class="flexrow" v-if="form.status >= 9">
      <div class="leftcg">
        <div class="flexrow lefttie" style="background-color: #ffffff">
          <div class="ltleft">
            审查资料
            <a href="https://api.gbservice.cn/storage/uploads/enterprise/2023-11-17/f4dfc67d71b2bcbfbbc235aa1e2227a4.rar"
              download="企业版-征求意见.zip" style="color: #3086fb; text-decoration: none; font-size: 12px">
              <el-button type="danger" round size="mini">模板下载<i class="el-icon-bottom"></i></el-button></a>
          </div>
          <div class="ltright">
            <div v-if="form.status == 9" style="font-size: 12px; color: #f56c6c; margin-top: 5px">
              请在3个月内上传完成
              <span>{{ getLeftTime() }}</span>
            </div>
          </div>
        </div>
        <div class="leftfile">
          <div class="filelistcg" v-if="materImgList">
            <div class="cgfiles">
              <div class="flexrow cgtop">
                <div class="cgfleft">
                  <div>《初稿专家意见汇总表》<el-tag effect="dark" size="mini" type="danger">必须上传</el-tag></div>
                  <!-- <div class="beizhu">这是一条备注</div> -->
                </div>
                <div class="cgfrig">
                  <el-upload accept=".pdf,.jpg,.png,.doc,.docx" :before-upload="showLoad" :data="{
                    token: $store.state.token,
                  }" v-if="form.status == 9" :file-list="materImgList1" :on-success="(response, file, fileList) =>
  changeMaterImg(1, response, file, fileList)
  " :show-file-list="false" action="https://api.gbservice.cn/api/public/bupload" class="upload-demo">
                    <el-button size="small" type="primary">上传文件 </el-button>
                  </el-upload>
                  <el-upload :before-upload="showLoad" :data="{
                    token: $store.state.token,
                  }" v-else disabled :file-list="materImgList1" :on-success="(response, file, fileList) =>
  changeMaterImg(1, response, file, fileList)
  " :show-file-list="false" action="https://api.gbservice.cn/api/public/bupload" class="upload-demo">
                    <el-button size="small" type="primary" disabled>上传文件
                    </el-button>
                  </el-upload>
                </div>
              </div>
              <div v-for="(item, index) in materImgList1" :key="index" class="flexrow cgtop">
                <div class="cgfleft" @click="zhanshi2(item)">
                  <div>
                    <div style="color: #999999" class="filename">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
                <div class="cgfrig" v-if="form.status == 9" @click="delbtn(1, index)">
                  删除
                </div>
              </div>
            </div>
            <div class="cgfiles">
              <div class="flexrow cgtop">
                <div class="cgfleft">
                  <div>《征求意见稿》（word文档）<el-tag effect="dark" size="mini" type="danger">必须上传</el-tag></div>
                  <!-- <div class="beizhu">这是一条备注</div> -->
                </div>
                <div class="cgfrig">
                  <el-upload accept=".pdf,.jpg,.png,.doc,.docx" :data="{
                    token: $store.state.token,
                  }" :before-upload="showLoad" :file-list="materImgList3" :on-success="(response, file, fileList) =>
  changeMaterImg(3, response, file, fileList)
  " v-if="form.status == 9" :show-file-list="false" action="https://api.gbservice.cn/api/public/bupload"
                    class="upload-demo">
                    <el-button size="small" type="primary">上传文件 </el-button>
                  </el-upload>
                  <el-upload :data="{
                    token: $store.state.token,
                  }" :before-upload="showLoad" :file-list="materImgList3" :on-success="(response, file, fileList) =>
  changeMaterImg(3, response, file, fileList)
  " v-else disabled :show-file-list="false" action="https://api.gbservice.cn/api/public/bupload" class="upload-demo">
                    <el-button size="small" type="primary" disabled>上传文件
                    </el-button>
                  </el-upload>
                </div>
              </div>
              <div v-for="(item, index) in materImgList3" :key="index" class="flexrow cgtop">
                <div class="cgfleft" @click="zhanshi2(item)">
                  <div>
                    <div style="color: #999999" class="filename">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
                <div class="cgfrig" v-if="form.status == 9" @click="delbtn(3, index)">
                  删除
                </div>
              </div>
            </div>
            <!-- <div class="cgfiles">
              <div class="flexrow cgtop">
                <div class="cgfleft">
                  <div>《初稿审查会会议纪要》</div>
                </div>
                <div class="cgfrig">
                  <el-upload
                    accept=".pdf,.jpg,.png,.doc,.docx"
                    :data="{
                      token: $store.state.token,
                    }"
                    :before-upload="showLoad"
                    :file-list="materImgList2"
                    :on-success="
                      (response, file, fileList) =>
                        changeMaterImg(2, response, file, fileList)
                    "
                    v-if="form.status == 9"
                    :show-file-list="false"
                    action="https://api.gbservice.cn/api/public/bupload"
                    class="upload-demo"
                  >
                    <el-button size="small" type="primary">上传文件 </el-button>
                  </el-upload>
                  <el-upload
                    :data="{
                      token: $store.state.token,
                    }"
                    :before-upload="showLoad"
                    :file-list="materImgList2"
                    :on-success="
                      (response, file, fileList) =>
                        changeMaterImg(2, response, file, fileList)
                    "
                    v-else
                    disabled
                    :show-file-list="false"
                    action="https://api.gbservice.cn/api/public/bupload"
                    class="upload-demo"
                  >
                    <el-button size="small" type="primary" disabled
                      >上传文件
                    </el-button>
                  </el-upload>
                </div>
              </div>
              <div
                v-for="(item, index) in materImgList2"
                :key="index"
                class="flexrow cgtop"
                @click="zhanshi2(item)"
              >
                <div class="cgfleft">
                  <div>
                    <div style="color: #999999" class="filename">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
                <div
                  class="cgfrig"
                  v-if="form.status == 9"
                  @click="delbtn(2, index)"
                >
                  删除
                </div>
              </div>
            </div> -->

            <div class="cgfiles">
              <div class="flexrow cgtop">
                <div class="cgfleft">
                  <div class="flexrow_tt">
                    《变更申请》
                    <div style="
                        margin-left: 15px;
                        color: #3082e2;
                        cursor: pointer;
                        font-size: 12px;
                        margin-top: 6px;
                      "></div>
                  </div>
                </div>
                <div class="cgfrig">
                  <el-upload accept=".pdf,.jpg,.png,.doc,.docx" :data="{
                    token: $store.state.token,
                  }" :before-upload="showLoad" :file-list="materImgList4" :on-success="(response, file, fileList) =>
  changeMaterImg(4, response, file, fileList)
  " :show-file-list="false" :on-remove="removeImg" v-if="form.status == 9"
                    action="https://api.gbservice.cn/api/public/bupload" class="upload-demo">
                    <el-button size="small" type="primary" v-if="form.status == 9">上传文件
                    </el-button>
                  </el-upload>
                  <el-upload :data="{
                    token: $store.state.token,
                  }" :before-upload="showLoad" :file-list="materImgList4" :on-success="(response, file, fileList) =>
  changeMaterImg(4, response, file, fileList)
  " :show-file-list="false" :on-remove="removeImg" v-else disabled action="https://api.gbservice.cn/api/public/bupload"
                    class="upload-demo">
                    <el-button size="small" type="primary" disabled>上传文件
                    </el-button>
                  </el-upload>
                </div>
              </div>
              <div class="flexrow cgtop" v-for="(item, index) in materImgList4" :key="index">
                <div class="cgfleft" @click="zhanshi2(item)">
                  <div>
                    <div style="color: #999999" class="filename">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
                <div class="cgfrig" @click="delbtn(4, index)">删除</div>
              </div>
            </div>
          </div>
          <div class="sendbtn">
            <el-button type="success" @click="addmit" :disabled="disabled" v-if="form.status == 9">提交审查</el-button>
            <el-button type="success" @click="addmit" :disabled="todisabled"
              v-else-if="form.status == 10">提交已审查</el-button>
            <el-button type="success" disabled v-else>征求意见稿审查已结束</el-button>
          </div>
        </div>
      </div>
      <div class="rightcg">
        <div class="lefttie" style="margin-bottom: 10px; border-bottom: none">
          <div class="ltleft">审查意见</div>
        </div>
        <div v-for="(activity, index) in activities" :key="index">
          <div class="kuang">
            <div class="cr-card">
              <div class="checkcont">
                {{ activity.content }}
              </div>
            </div>
            <div class="chfiles">
              <div class="cgfleft" v-for="(item, index) in activeFile(activity.type)" :key="index">
                <el-tooltip class="item" effect="light" :content="item.name" placement="bottom">
                  <div class="cgfleftto" @click="zhanshi(item)">
                    {{ item.name }}
                  </div>
                </el-tooltip>
              </div>
            </div>
            <div class="linetie">
              <div class="lttime">
                <i class="el-icon-time"></i>
                {{ activity.created_at | formatDate }}
              </div>
            </div>
          </div>
          <!-- <el-timeline :reverse="reverse">
            <el-timeline-item
              :color="index == activities.length - 1 ? '#3082e2' : ''"
            >
              <div class="flex-row">
                <div
                  class="checktitle"
                  :style="
                    index == activities.length - 1 ? 'color: #3082e2' : ''
                  "
                >
                  {{ activity.type | shenchaType }}
                </div>
                <div class="shehe">审核人：{{ activity.uname }}</div>
              </div>
              
            </el-timeline-item>
          </el-timeline> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Loading } from "element-ui";
import { addfiles, getBiaozhunDetail } from "../../../../api/project";
import { filterArr } from '../../../../utils/config'
export default {
  filters: {
    shenchaType(type) {
      if (type == 1 || type == 4) {
        return "初审";
      } else if (type == 2 || type == 5) {
        return "复审";
      } else if (type == 3 || type == 6) {
        return "终审";
      } else if (type == 7) {
        return "最终意见";
      }
    },
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd hh:mm:ss";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      materImgList1: [],
      materImgList2: [],
      materImgList3: [],
      materImgList4: [],
      materImgList5: [],
      materImgList: [],
      materImgList7: [],
      nowType: 1,
      reverse: false,
      types: [],
      activities: [],
      form: {},
      disabled: false,
      todisabled: true,
      loadingInstance: "",
    };
  },
  mounted() {
    this.getIdAndToken();
  },
  methods: {
    zhanshi2(item) {
      console.log("file", item);
      // this.dialogfiles = true;
      // this.viewFile(row.url, row.name)
      window.open(item.url);
    },
    zhanshi(item) {
      console.log("2222");
      // this.dialogfiles = true;
      // this.viewFile(row.url, row.name)
      window.open(item.url);
    },
    activeFile(type) {
      if (type == 1 || type == 4) {
        return this.materImgList7.filter((item) => item.type == 34);
      } else if (type == 2 || type == 5) {
        return this.materImgList7.filter((item) => item.type == 35);
      } else if (type == 3 || type == 6) {
        return this.materImgList7.filter((item) => item.type == 36);
      } else if (type == 7) {
        return this.materImgList7.filter((item) => item.type == 44);
      }
    },
    getLeftTime() {
      //开始的毫秒数
      let start;
      if (this.activities && this.activities.length > 0) {
        start = this.activities[this.activities.length - 1].updated_at;
      } else if (this.form.examines && this.form.examines.length > 0) {
        start = this.form.examines[this.form.examines.length - 1].updated_at;
      }
      //到期的时间 毫秒数
      let end = new Date(start).getTime() + 3 * 30 * 24 * 60 * 60 * 1000;
      //剩余的毫秒数
      let left = end - new Date().getTime();

      let day = parseInt(left / (24 * 60 * 60 * 1000)) % 30;
      let month = parseInt(left / (30 * 24 * 60 * 60 * 1000));
      let result = day + "天截止";
      if (month) {
        result = month + "月" + result;
      }
      return "剩余" + result;
    },
    showNowType() {
      let lastType = 0;
      if (this.activities.length > 0) {
        lastType = this.activities[this.activities.length - 1].type;
      }
      if (lastType == 0 || lastType == 4 || lastType == 5 || lastType == 6) {
        this.nowType = 1;
      } else if (lastType == 1) {
        this.nowType = 2;
      } else if (lastType == 2) {
        this.nowType = 3;
      } else if (lastType == 3) {
        this.nowType = 7;
      } else {
        this.nowType = -1;
      }
      console.log(this.activities, this.nowType);
      this.$emit("changeNowType", this.nowType == -1 ? 7 : this.nowType);
    },
    showLoad(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ["pdf", "jpg", "png", "doc", "docx"];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message("上传文件只能是 pdf、jpg、png、doc、docx格式", "error");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 16;
      if (isLt2M) {
        this.loadingInstance = Loading.service({ fullscreen: true });
        console.log(this.loadingInstance);
      }
      if (!isLt2M) {
        this.$message("文件格式不正确或文件的大小超出上限");
      }
      return isLt2M;
    },
    goback() {
      this.$router.go(-1);
    },

    changeMaterImg(index, response, file, fileList) {
      if (index == 1) {
        if (this.materImgList1 && this.materImgList1.length > 0) {
          this.materImgList1.push(response.data);
          this.materImgList1.splice(0, 1);
        } else {
          this.materImgList1.push(response.data);
        }
      } else if (index == 2) {
        if (this.materImgList2 && this.materImgList2.length > 0) {
          this.materImgList2.push(response.data);
          this.materImgList2.splice(0, 1);
        } else {
          this.materImgList2.push(response.data);
        }
      } else if (index == 3) {
        if (this.materImgList3 && this.materImgList3.length > 0) {
          this.materImgList3.push(response.data);
          this.materImgList3.splice(0, 1);
        } else {
          this.materImgList3.push(response.data);
        }
      } else if (index == 4) {
        if (this.materImgList4 && this.materImgList4.length > 0) {
          this.materImgList4.push(response.data);
          this.materImgList4.splice(0, 1);
        } else {
          this.materImgList4.push(response.data);
        }
      }
      this.$nextTick(() => {
        this.loadingInstance.close();
      });
    },
    removeImg(file, fileList) {
      console.log(file, fileList);
      this.materImgList1 = fileList;
      this.materImgList2 = fileList;
      this.materImgList3 = fileList;
      this.materImgList4 = fileList;
      this.materImgList5 = fileList;
      this.materImgList6 = fileList;
      this.materImgList7 = fileList;
      this.materImgList8 = fileList;
      this.materImgList10 = fileList;
    },
    delbtn(index, fileIndex) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (index == 1) {
          this.materImgList1.splice(fileIndex, 1);
        } else if (index == 2) {
          this.materImgList2.splice(fileIndex, 1);
        } else if (index == 3) {
          this.materImgList3.splice(fileIndex, 1);
        } else if (index == 4) {
          this.materImgList4.splice(fileIndex, 1);
        }
      }).catch(() => {

      });
    },
    addmit() {
      this.disabled = true;
      let index = 1;
      let files_mb = [];
      this.materImgList1.forEach((item) => (item.type = 6));
      this.materImgList2.forEach((item) => (item.type = 7));
      this.materImgList3.forEach((item) => (item.type = 8));
      this.materImgList4.forEach((item) => (item.type = 9));
      files_mb = files_mb.concat(this.materImgList1);
      files_mb = files_mb.concat(this.materImgList2);
      files_mb = files_mb.concat(this.materImgList3);
      files_mb = files_mb.concat(this.materImgList4);
      // files_mb.forEach((item) => {
      //   item.type = index;
      //   index = index + 1;
      // });

      let lctype = 0;
      if (this.activities.length > 0) {
        lctype = this.activities[this.activities.length - 1].type;
      }
      let types = [6, 7, 8];
      //数组去重
      let newFiles = filterArr(files_mb)

      this.$confirm("是否确认提交, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (lctype == 4 || lctype == 5 || lctype == 6) {
          // console.log("11111");
          let params = {
            token: this.$store.state.token,
            files: newFiles,
            sid: this.$route.query.id,
            status: "10",
            type: "2",
            types: types.join(","),
          };
          addfiles(params)
            .then((res) => {
              console.log(res);
              this.$message.success("提交审查成功");
              this.getIdAndToken();
            })
            .catch((err) => {
              this.disabled = false;
            });
        } else if (lctype == 1 || lctype == 2 || lctype == 3) {
          console.log("22222");
          this.disabled = true;
          this.$message.success("正在审查中");
        } else {
          console.log("333333");
          let params = {
            token: this.$store.state.token,
            files: newFiles,
            sid: this.$route.query.id,
            status: "10",
          };
          addfiles(params)
            .then((res) => {
              console.log(res);
              this.$message.success("提交审查成功");
              this.getIdAndToken();
            })
            .catch((err) => {
              this.disabled = false;
            });
        }
      }).catch(() => {
        //取消
      });

    },
    getIdAndToken() {
      this.id = this.$route.query.id;
      this.token = this.$store.state.token; //获取token
      this.getInfo();
    },
    //获取详情
    getInfo() {
      getBiaozhunDetail({ id: this.id, token: this.token })
        .then((res) => {
          // console.log("detial", res);
          this.htmlTitle = res.data.proName;
          this.form = res.data;
          this.tableData = res.data.fu; //主要起草人员展开无分页
          this.materImgList = res.data.files;
          this.materImgList7 = res.data.files.filter(
            (item) =>
              item.type == 34 ||
              item.type == 35 ||
              item.type == 36 ||
              item.type == 44
          );
          let activities = res.data.examines.filter(
            (item) =>
              (item.status == 10 || item.status == 9) &&
              (item.type == 4 ||
                item.type == 5 ||
                item.type == 6 ||
                item.type == 3 ||
                item.type == 7)
          );
          if (activities && activities.length) {
            this.activities = [activities[activities.length - 1]];
          }
          
          let newFiles = filterArr(this.materImgList)
          this.materImgList1 = newFiles.filter((item) => item.type == 6);
          this.materImgList2 = newFiles.filter((item) => item.type == 7);
          this.materImgList3 = newFiles.filter((item) => item.type == 8);
          this.materImgList4 = newFiles.filter((item) => item.type == 9);
          // this.tableData = res.data.fu.slice(   主要起草人员分页
          //   (this.page - 1) * this.size,
          //   this.page * this.size
          // );
          // this.total=res.data.fu.length
          this.showNowType();
          console.log(this.form);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
/deep/.el-timeline-item__node--normal {
  margin-top: 8px;
}
</style>
<style scoped lang="scss">
.chfiles {
  border-top: 1px solid #d8d8d8;
  margin-top: 10px;
  padding-top: 10px;
}

.filename {
  cursor: pointer;
}

.filename:hover {
  color: #3082e2;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.shehe {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.flexrow_tt {
  display: flex;
  flex-direction: row;
}

.checktitle {
  font-size: 18px;
  font-weight: bold;
  color: #999999;
  padding-top: 3px;
}

.sendbtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.flexrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tilelft {
  font-size: 22px;
  font-weight: bold;
  color: #1a1a1a;
}

.tiecg {
  margin-bottom: 20px;
}

.lefttie {
  border-bottom: 1px solid #dbe9ef;
  padding: 5px;

  .ltleft {
    border-left: 4px solid #3082e2;
    padding-left: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #3082e2;
  }

  .ltright {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.leftcg {
  width: 64%;
  // background: #ffffff;
}

.rightcg {
  width: 35%;
  height: 73vh;
  overflow: hidden;
  padding-right: 20px;
  padding-left: 40px;
  overflow-y: scroll;
}

.rightcg::-webkit-scrollbar {
  display: none;
}

.linetie {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  margin-top: 15px;

  .lttime {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }

  .ltmanger {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    padding-right: 10px;
  }
}

.kuang {
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.29);
  margin: 10px 0;
  padding: 10px 15px;
}

.cr-card {
  .checkcont {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    // border-bottom: 1px solid #dbe9ef;
  }
}

.tgbtn {
  padding: 30px 58px;
}

.leftfile {
  padding: 10px 20px;
  background-color: #ffffff;

  .filelistcg {
    .cgfiles {
      // height: 54px;
      background: #f4f4f4;
      // line-height: 54px;
      padding: 20px 15px;
      margin-bottom: 10px;

      .cgtop:nth-child(1) {
        border-bottom: 1px solid #dbe9ef;
      }

      .cgtop:nth-child(2) {
        margin-top: 10px;
      }

      .cgfleft {
        font-size: 16px;
        font-weight: 400;
        width: 80%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #666666;

        .beizhu {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          padding-left: 15px;
          height: 35px;
          line-height: 35px;
        }
      }

      .cgfrig {
        font-size: 14px;
        font-weight: 400;
        color: #f56c6c;
        cursor: pointer;
      }
    }
  }
}

.cgfleftto {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.cgfleftto:hover {
  color: #3082e2;
  cursor: pointer;
}
</style>
